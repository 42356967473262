import React, { Component } from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import {
  BlockImageTextHalf,
  HalfWidthBlock,
  Medailon,
  PaddingWrapper,
  ReferenceContainer,
  TitleH2,
} from "@igloonet-web/shared-ui"
import ReferenceBanner from "@igloonet-web/shared-ui/components/reference/reference-banner"
import ReferenceBannerContent from "@igloonet-web/shared-ui/components/reference/reference-banner-content"

import Top from "../../../images/reference/planetaher/top.png"
import Logo from "../../../images/reference/planetaher/logo-planeta.png"
import Header from "../../../images/reference/planetaher/header-marketing.png"
import Sideimg from "../../../images/reference/planetaher/screen-1.png"

class PlanetaHer extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    const pozadi = {
      backgroundImage: `linear-gradient(rgba(10,75,54,0.9),rgba(0,49,85,0.9)),url(${Top})`,
    }

    return (
      <Layout>
        <Helmet>
          <title>Reference na nový e-shop Planeta her | igloonet</title>
          <meta
            name="description"
            content="Marketing, vývoj a hosting pod střechou jedné firmy. Programujeme moderní eshopy, poskytujeme prvotřídní hostingové služby a tvoříme marketingové strategie na míru."
          />
        </Helmet>
        <ReferenceBanner
          styles={pozadi}
          logo={Logo}
          alt="logo"
          extodkaz="//planetaher.cz"
          heading="Reference na nový e-shop Planeta her"
          odstavec="Q3 2015 - Q1 2016"
        >
          <ReferenceBannerContent
            image={{
              src: Header,
            }}
            mainText="Planetaher.cz je specializovaný obchod s moderními společenskými hrami, který vznikl v roce 2007. Jejich e‑shop obsahuje jeden z největších výběrů her v České republice. Kromě běžně dostupných her je v obchodě možno zakoupit především hry, které si získaly obrovskou oblibu v zahraničí a na českém trhu nejsou běžně k dostání. Roku 2011 pak tým rozšířil své aktivity a založil vlastní vydavatelství deskových her - REXhry, které se zabývá vydáváním zahraničních her pro začátečníky i náročné hráče."
          />
        </ReferenceBanner>

        <ReferenceContainer>
          <HalfWidthBlock>
            <PaddingWrapper>
              <TitleH2>Zadání</TitleH2>
              <ul>
                <li>
                  Navrhnout novou vizuální podobu e‑shopu a zajistit jeho
                  kompletní realizaci.
                </li>
                <li>
                  Vytvořit vlastní funkce a nahradit nevhodný ekonomický
                  software.
                </li>
                <li>Exportovat doklady do účetního programu.</li>
              </ul>
            </PaddingWrapper>

            <PaddingWrapper>
              <TitleH2>Rozsah projektu</TitleH2>
              <p>
                S internetovým obchodem Planetaher.cz jsme v oblasti{" "}
                <Link to="/marketing/reference/planetaher">
                  online marketingu
                </Link>{" "}
                začali spolupracovat již v listopadu 2013. Tehdy jsme převzali
                stávající PPC kampaně v AdWords a Sklik. Naše dlouholetá
                spolupráce vyvrcholila v roce 2016 vývojem nového e‑shopu.
              </p>
              <p>
                Díky blízké spolupráci s klientem jsme dokázali vyřešit také
                jeho dílčí problémy a úspěšně jsme zprovoznili vlastní pokladní
                systém, který umožňuje správu objednávek přes nový e‑shop.
              </p>
            </PaddingWrapper>
          </HalfWidthBlock>

          <PaddingWrapper>
            <BlockImageTextHalf
              className="d-md-none"
              image={Sideimg}
              alt={"ALT"}
              right
              hideImageOnSmall
            >
              <TitleH2>Historie spolupráce</TitleH2>
              <ul>
                <li>
                  Listopad 2013 - opravení stávajících kampaní ve vyhledávání
                </li>
                <li>
                  Prosinec 2013 - spuštění remarketingu s textovými reklamami,
                  PLA kampaně a předvánoční kampaně na Facebooku
                </li>
                <li>
                  2014 až současnost - kompletní správa kampaní AdWords a Sklik,
                  remarketing na Facebooku, správa zbožových srovnávačů
                </li>
                <li>2015 - počátek prací na vývoji nového webu</li>
                <li>2016 - zveřejnění nového e‑shopu</li>
              </ul>
            </BlockImageTextHalf>
          </PaddingWrapper>

          <Medailon
            wide
            text="igloonet je pro nás super partner jak lidsky, tak pracovně."
            name="Michal Široký"
            position="spolumajitel"
          />
        </ReferenceContainer>

        <ContactForm contact="sobol" />
      </Layout>
    )
  }
}

export default PlanetaHer
